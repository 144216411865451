<!--
 * @Description: 编辑属性值
 * @Author: 琢磨先生
 * @Date: 2022-06-07 14:38:44
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-07 15:16:02
-->


<template>
  <el-dialog
    :title="title"
    v-model="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    @close="close"
  >
    <el-button type="primary" size="small" @click="onAdd" round icon="plus"
      >新增</el-button
    >
    <el-table :data="tableData">
      <el-table-column label="属性值" prop="value"></el-table-column>
      <el-table-column label="停用" width="100">
        <template #default="scope">
          <el-switch
            v-model="scope.row.is_stop"
            @change="stopSwitch(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="140">
        <template #default="scope">
          <el-popconfirm
            title="您确定要删除？"
            @confirm="onDelete(scope.row, scope.$index)"
          >
            <template #reference>
              <el-button size="small" type="text" class="btn-del"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      title: "",
      tableData: [],
    };
  },
  emits: ["change", "close"],
  props: ["item"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.dialogVisible = true;
          this.title = `设置属性值【${this.item.name}】`;
          this.tableData = this.item.values;
        }
      },
      immediate: true,
    },
  },
  methods: {
    onAdd() {
      this.$prompt("", "添加属性值", {
        inputPattern: /.+/,
        inputErrorMessage: "属性值必填",
      })
        .then((val) => {
          this.$http
            .post("admin/v1/property/value/add", {
              value: val.value,
              property_id: this.item.id,
            })
            .then((res) => {
              if (res.code == 0) {
                this.tableData.push(res.data);
                this.is_change = true;
              }
            });
        })
        .catch(() => {});
    },
    stopSwitch(row) {
      this.$http
        .get(
          `admin/v1/property/value/toggle?id=${row.id}&isStop=${row.is_stop}`
        )
        .then((res) => {
          if (res.code != 0) {
            row.is_stop = !row.is_stop;
          }
        });
    },
    /**
     * 删除属性值
     */
    onDelete(row, index) {
      this.is_change = true;
      this.$http.get("admin/v1/property/value/del?id=" + row.id).then((res) => {
        if (res.code == 0) {
          this.tableData.splice(index, 1);
        }
      });
    },
    /**
     * 关闭
     */
    close() {
      this.dialogVisible = false;
      if (this.is_change) {
        this.$emit("change");
      } else {
        this.$emit("close");
      }
    },
  },
};
</script>

<style  scoped>
</style>